.loadingico
{
  float: left;
  color: #c3c3c3;
  font-size: 2vw;
  margin-left: 0.5vw;
  margin-top: 0.5vw;
}

.loadingtext
{
  float: left;
  color: #c3c3c3;
  font-size: 1vw;
  margin-left: 0.5vw;
  margin-top: 0.5vw;
}
.link
{
  float: left;
  color: White;
  font-size: 1vw;
  cursor: pointer;
  margin-left: 0.5vw;
  margin-top: 0.5vw;
}
.link:hover
{
  text-decoration: underline;
  color: DarkGray;
}
.link:active
{
  color:Gray;
}
