.origin
{
  float: left;
  margin-top: 0.5vw;
  margin-left: 0.5vw;
  color: #c3c3c3;
  font-size: 0.8vw;
}
.mailinput
{
  float: left;
  margin-top: 0.5vw;
  margin-left: 0.5vw;
  font-size: 1vw;
  color: White;
  background: rgba(0,0,0,0);
  width: calc(100% - 3vw);

  border:none;
  border-bottom:solid;
  border-color: White;
  border-width: 2px;

  font-family: "Roboto";

}
.mailinput::placeholder{

  color: #c3c3c3;
}
.subtitle
{
  float: left;
  margin-top: 0vw;
  margin-left: 0.5vw;
  font-size: 0.8vw;
  color: #c3c3c3;
}

.send
{
  float: right;
  margin-top: 0.5vw;
  font-size: 1vw;
  color: White;
  background: rgba(0,0,0,0);
  border:none;
}
.send:hover
{
  color: DarkGray;
}
.send:active
{
  color:Gray;
}
.inputrequest
{
  float: left;
  margin-top: 0.5vw;
  margin-left: 0.5vw;
  font-size: 0.8vw;
  color: #c3c3c3;
  width: 100%;
}
.error
{
  float: left;
  margin-top: 0.5vw;
  margin-left: 0.5vw;
  font-size: 0.8vw;
  color: rgb(255, 0, 00);
  width: 100%;
}
