html{
  font-family: "Roboto";
  font-weight: 400;
  margin: 0;
}
body{
  background-color: rgb(20,20,22);
  padding: 0;
  margin: 0;
}
p
{
  margin: 0;
}
