.fca{
  float: left;
  width: 30vw;
  margin-left: calc(50% - 15vw);
  margin-top: 10vw;
}

.fca_subunit
{
  float: left;
  margin-left: 1.3vw;
  width: calc(100% - 1.35vw);
  border-left:solid;
  border-color: Gray;
  border-width: 2px;
}
.title
{
  float: left;
  width:100%;
  margin-top: 0.5vw;
  margin-left: 0.5vw;
  margin-bottom: 0px;
  font-size: 2vw;
  color: White;
}
.text
{
  float: left;
  margin-top: 0vw;
  margin-left: 0.5vw;
  font-size: 0.8vw;
  color: #c3c3c3;
}

.inputfield
{
  float: left;
  margin-top: 0.5vw;
  margin-left: 0.5vw;
  font-size: 1vw;
  color: White;
  background: rgba(0,0,0,0);;
  width: calc(100% - 1vw);
  height: 1vw;
  border:none;
  border-bottom:solid;
  border-color: White;
  border-width: 2px;
}
.helplink
{
  float: left;
  margin-top: 0.7vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  font-size: 0.8vw;
  color: White;
  cursor: pointer;
}
.helplink:hover
{
  text-decoration: underline;
  color: DarkGray;
}
.helplink:active
{
  text-decoration: underline;
  color: Gray;
}
